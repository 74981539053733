import { SaveUserInfoSchema } from "../@types/quiz";

const regularServerResponse = `prompt
mode
sessionMetadata {
    sessionId
    totalPrompts
    currentPromptCount
}
isSessionOpen
timeLimitForVoiceAnswerSec
uploadResource {
    key
    url
}
contextId`;

const submitResumeResponse = `
  sessionId
  userInfo {
    firstName
    lastName
    email
    positions {
      company
      startDate
      endDate
      title
    }
    finalized
  }
  downloadUrl`;

const saveUserResponse = `
  sessionId
  userInfo {
    firstName
    lastName
    email
    positions {
      company
      startDate
      endDate
      title
    }
    finalized
  }`;

const getUserResponse = `
  firstName
  lastName
  email
  positions {
    company
    startDate
    endDate
    title
  }
  finalized
  showJobHistory
  `;

export const getProjectSchema = (projectId: string) => {
  return `{
    getProjectInfo(projectId: "${projectId}") {
      resumeUploadResource {
        key
        url
      }
    }
  }`;
};

export const getUserSchema = (sessionID: string) => {
  return `{
        getUserInfo(sessionId: "${sessionID}") {
            ${getUserResponse}
        }
    }`;
};

export const getConnectSchema = (sessionID: string) => {
  return `{
        getUserPrompt(sessionId: "${sessionID}") {
            ${regularServerResponse}
        }
    }`;
};

export const getSubmitUserResponseSchema = (mutationStr: string) => {
  return `
        mutation {
            submitUserResponse(
              userResponse: ${mutationStr}
            ) {
              ${regularServerResponse}
            }
          }
    `;
};

export const getSubmitResumeResponseSchema = (
  projectId: string,
  resumeUploadKey: string
) => {
  return `
        mutation {
            submitResume(
              projectId: "${projectId}"
              resumeUploadKey: "${resumeUploadKey}"
            ) {
              ${submitResumeResponse}
            }
          }
    `;
};

export const getSaveUserInfoSchema = (
  sessionId: string,
  user: SaveUserInfoSchema
) => {
  return `
        mutation {
          saveUserInfo(
              sessionId: "${sessionId}"
              userInfo: {
                firstName: "${user.firstName}"
                lastName: "${user.lastName}",
                email: "${user.email}"
                positions: [{
                  company: "${user.position0Name}"
                  title: "${user.position0Title}"
                  startDate: "${user.position0StartDate}"
                  endDate: ${
                    user.position0EndDate ? `"${user.position0EndDate}"` : null
                  }
                }
                ${
                  user.position1Name
                    ? `, {
                  company: "${user.position1Name}"
                  title: "${user.position1Title}"
                  startDate: "${user.position1StartDate}"
                  endDate: ${
                    user.position1EndDate ? `"${user.position1EndDate}"` : null
                  }
                }`
                    : ""
                } 
                ${
                  user.position2Name
                    ? `, {
                  company: "${user.position2Name}"
                  title: "${user.position2Title}"
                  startDate: "${user.position2StartDate}"
                  endDate: ${
                    user.position2EndDate ? `"${user.position2EndDate}"` : null
                  }
                }`
                    : ""
                }]
              }
            ) {
              ${saveUserResponse}
            }
          }
    `;
};
