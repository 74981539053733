import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import {
  InteractionMode,
  UploadResource,
  UserInfoResponse,
} from "../@types/quiz";
import { LayoutString } from "../@types/layout";

type LocalErrorCaller = string;

interface SliceState {
  projectId: string;
  sessionId?: string;
  mode: string;
  progressStepsCurrent: number;
  progressStepsTotal: number;
  progressTime: number;
  onboardingScreenIndex?: number;
  appLayout: string;
  question: string;
  timeLimitForVoiceAnswerSec?: number;
  contextId?: string;
  uploadResource?: UploadResource;
  resumeUploadResource?: UploadResource;
  cv?: string;
  userInfo: UserInfoResponse;
  userLocalFinalized?: boolean;
  loadingMessage?: string;
  globalError?: string;
  localError?: string;
  localErrorCaller?: LocalErrorCaller;
}

export const slice = createSlice({
  name: "session",
  initialState: {
    projectId: "",
    sessionId: "",
    mode: "VOICE",
    progressStepsCurrent: 0,
    progressStepsTotal: 0,
    progressTime: 0,
    onboardingScreenIndex: 0,
    appLayout: "",
    question: "",
    timeLimitForVoiceAnswerSec: 300,
    contextId: "",
    uploadResource: {
      key: "",
      url: "",
    },
    resumeUploadResource: {
      key: "",
      url: "",
    },
    cv: "",
    userInfo: {
      firstName: "",
      lastName: "",
      email: "",
      positions: [
        {
          company: "",
          startDate: "",
          endDate: "",
          title: "",
        },
        {
          company: "",
          startDate: "",
          endDate: "",
          title: "",
        },
        {
          company: "",
          startDate: "",
          endDate: "",
          title: "",
        },
      ],
      finalized: false,
      showJobHistory: true
    },
    userLocalFinalized: false,
    loadingMessage: "",
    globalError: "",
    localError: "",
    localErrorCaller: "",
  },
  reducers: {
    updateProject: (state: SliceState, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    updateSession: (state: SliceState, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    updateMode: (state: SliceState, action: PayloadAction<InteractionMode>) => {
      state.mode = action.payload;
    },
    updateProgressStepsCurrent: (
      state: SliceState,
      action: PayloadAction<number>
    ) => {
      state.progressStepsCurrent = action.payload;
    },
    updateProgressStepsTotal: (
      state: SliceState,
      action: PayloadAction<number>
    ) => {
      state.progressStepsTotal = action.payload;
    },
    updateProgressTime: (state: SliceState, action: PayloadAction<number>) => {
      state.progressTime = action.payload;
    },
    updateOnboardingScreenIndex: (
      state: SliceState,
      action: PayloadAction<number>
    ) => {
      state.onboardingScreenIndex = action.payload;
    },
    updateAppLayout: (
      state: SliceState,
      action: PayloadAction<LayoutString>
    ) => {
      state.appLayout = action.payload;
    },
    updateQuestion: (state: SliceState, action: PayloadAction<string>) => {
      state.question = action.payload;
    },
    updateTimeLimitForVoiceAnswerSec: (
      state: SliceState,
      action: PayloadAction<number>
    ) => {
      state.timeLimitForVoiceAnswerSec = action.payload;
    },
    updateContextId: (state: SliceState, action: PayloadAction<string>) => {
      state.contextId = action.payload;
    },
    updateUploadResource: (
      state: SliceState,
      action: PayloadAction<UploadResource>
    ) => {
      state.uploadResource = action.payload;
    },
    updateCV: (state: SliceState, action: PayloadAction<string>) => {
      state.cv = action.payload;
    },
    updateResumeUploadResource: (
      state: SliceState,
      action: PayloadAction<UploadResource>
    ) => {
      state.resumeUploadResource = action.payload;
    },
    updateUserInfo: (
      state: SliceState,
      action: PayloadAction<UserInfoResponse>
    ) => {
      state.userInfo = action.payload;
    },
    updateUserLocalFinalized: (
      state: SliceState,
      action: PayloadAction<boolean>
    ) => {
      state.userLocalFinalized = action.payload;
    },
    updateLoadingMessage: (
      state: SliceState,
      action: PayloadAction<string>
    ) => {
      state.loadingMessage = action.payload;
      state.appLayout = "loading";
    },
    updateGlobalError: (state: SliceState, action: PayloadAction<string>) => {
      state.globalError = action.payload;
      state.appLayout = "404";
    },
    updateLocalError: (
      state: SliceState,
      action: PayloadAction<{ error: string; caller: LocalErrorCaller }>
    ) => {
      state.localError = action.payload.error;
      state.localErrorCaller = action.payload.caller;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateProject,
  updateSession,
  updateMode,
  updateProgressStepsCurrent,
  updateProgressStepsTotal,
  updateProgressTime,
  updateOnboardingScreenIndex,
  updateAppLayout,
  updateQuestion,
  updateTimeLimitForVoiceAnswerSec,
  updateContextId,
  updateUploadResource,
  updateResumeUploadResource,
  updateCV,
  updateUserInfo,
  updateUserLocalFinalized,
  updateLoadingMessage,
  updateGlobalError,
  updateLocalError,
} = slice.actions;

export const getState = createSelector(
  (state: RootState) => state.session,
  (state) => {
    const {
      projectId,
      sessionId,
      mode,
      progressStepsCurrent,
      progressStepsTotal,
      progressTime,
      onboardingScreenIndex,
      appLayout,
      question,
      timeLimitForVoiceAnswerSec,
      contextId,
      uploadResource,
      resumeUploadResource,
      cv,
      userInfo,
      userLocalFinalized,
      loadingMessage,
      globalError,
      localError,
      localErrorCaller,
    } = state;
    return {
      projectId,
      sessionId,
      mode,
      progressStepsCurrent,
      progressStepsTotal,
      progressTime,
      onboardingScreenIndex,
      appLayout,
      question,
      timeLimitForVoiceAnswerSec,
      contextId,
      uploadResource,
      resumeUploadResource,
      cv,
      userInfo,
      userLocalFinalized,
      loadingMessage,
      globalError,
      localError,
      localErrorCaller,
    };
  }
);

export default slice.reducer;
